import { unref, computed } from "vue";

const state = {
  token: null,
  permissions: null,
};
const getters = {
  isAuthenticated: (state) => {
    if (state.token === null && localStorage.getItem('token') !== null) {
      state.token = localStorage.getItem('token');
    }

    return state.token !== null;
  },
  hasPermission: (state, getters) => (target, action) => {
    if (state.token === null && localStorage.getItem('token') !== null) {
      state.token = localStorage.getItem('token');
    }

    if (state.permissions === null) {
      const roles = JSON.parse(localStorage.getItem('roles'));
      if (!roles) { return false; }

      state.permissions = {};
      roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          const perm = permission.name.split(' ');
          if (!state.permissions[perm[1]]) {
            state.permissions[perm[1]] = [];
          }

          state.permissions[perm[1]].push(perm[0]);
        });
      });
    }

    const permissions = JSON.parse(JSON.stringify(state.permissions));

    if (state.token === null || permissions === null) { return false; }
    if (!permissions[target]) { return false; }
    if (!action) return true;
    return permissions[target].includes(action);
  },
};
const actions = {};
const mutations = {
  setToken (state, token) {
    state.token = token;
  },
  clearAuth(state) {
    state.token = null;
    state.permissions = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
