import { gql } from 'graphql-tag';

export const CREATE_USER = gql`
    mutation createUser(
        $name: String!
        $username: String!
        $email: String!
        $password: String!
    ) {
        createUser(
            name: $name
            username: $username
            email: $email
            password: $password
        ) {
            id
            name
            email
            username
            email_verified_at
            roles {
                id
                name
                permissions {
                    id
                    name
                }
            }
        }
    }
`

export const UPDATE_USER = gql`
    mutation updateUser(
        $id: ID!
        $name: String
        $username: String!
        $email: String!
        $old_password: String
        $new_password: String
    ) {
        updateUser(
            id: $id
            name: $name
            username: $username
            email: $email
            old_password: $old_password
            new_password: $new_password
        ) {
            id
            status
            name
            email
            username
            email_verified_at
            roles {
                id
                name
                permissions {
                    id
                    name
                }
            }
        }
    }
`

export const ASSIGN_ROLE = gql`
    mutation assignRole($id: ID!, $role: String!) {
        assignRole(id: $id, role: $role) {
            id
            name
            email
            username
            email_verified_at
            roles {
                id
                name
                permissions {
                    id
                    name
                }
            }
        }
    }
`

export const REMOVE_ROLE = gql`
    mutation removeRole($id: ID!, $role: String!) {
        removeRole(id: $id, role: $role) {
            id
            name
            email
            username
            email_verified_at
            roles {
                id
                name
                permissions {
                    id
                    name
                }
            }
        }
    }
`
