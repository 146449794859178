import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/forgot',
    name: 'Forgot Password',
    component: () => import('../views/Forgot.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "users" */ '../views/Settings/Users.vue')
  },
  {
    path: '/settings/user/:id',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/Settings/User.vue')
  },
  {
    path: '/settings/svn',
    name: 'SVN',
    component: () => import(/* webpackChunkName: "user" */ '../views/Settings/SVN.vue')
  },
  {
    path: '/games',
    name: 'Games',
    component: () => import(/* webpackChunkName: "games" */ '../views/Games/Overview.vue')
  },
  {
    path: '/contents',
    name: 'Contents',
    component: () => import(/* webpackChunkName: "contents" */ '../views/Contents/Overview.vue')
  },
  {
    path: '/contents/:id',
    name: 'Edit/Add Content',
    component: () => import(/* webpackChunkName: "content" */ '../views/Contents/Edit.vue')
  },
  {
    path: '/contents/publish',
    name: 'Publish',
    component: () => import(/* webpackChunkName: "user" */ '../views/Contents/Publish.vue')
  },
  {
    path: '/contents/:id/changes',
    name: 'View Content Changes',
    component: () => import(/* webpackChunkName: "changes" */ '../views/Contents/Changes.vue')
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import(/* webpackChunkName: "tags" */ '../views/Tags/Overview.vue')
  },
  {
    path: '/images',
    name: 'Images',
    component: () => import(/* webpackChunkName: "tags" */ '../views/Images/Overview.vue')
  },
  {
    path: '/images/upload',
    name: 'Upload',
    component: () => import(/* webpackChunkName: "tags" */ '../views/Images/Upload.vue')
  },
  {
    path: '/tags/:id',
    name: 'View Tag',
    component: () => import(/* webpackChunkName: "tag" */ '../views/Tags/View.vue')
  },
  {
    path: '/conflicts',
    name: 'Conflicts',
    component: () => import(/* webpackChunkName: "tags" */ '../views/Conflicts/Overview.vue')
  },
  {
    path: '/conflicts/:id',
    name: 'View Conflict',
    component: () => import(/* webpackChunkName: "conflict" */ '../views/Conflicts/View.vue')
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
