<template>
  <notifications/>
  <div class="font-sans antialiased h-screen" v-if="isAuthenticated">
    <NavBar/>
    <div id="main" class="pt-16">
      <SideBar/>
      <div class="relative h-full min-h-screen">
        <div class="bg-white h-full pt-8">
          <div class="container flex w-full text-grey-darkest">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view/>
  </div>
</template>

<script>
import NavBar from './components/Navbar.vue';
import SideBar from './components/Sidebar.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import {getMe} from "@/compose/users";
import {useRouter} from "vue-router";

export default {
  components: {
    NavBar,
    SideBar,
  },
  setup () {
    const store = useStore();
    const router = useRouter();

    const { meOnResult } = getMe(true);

    meOnResult((res) => {
      if (!res.data.me) {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        store.commit('auth/clearAuth');
        router.replace('/');
      } else if (res.data.me.status !== 'ready' && router.currentRoute.value.name !== 'Profile') {
        router.replace('/profile');
      }
    })

    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);
    return { isAuthenticated };
  }
};
</script>

<style scoped>
#main {
  display: grid;
  grid-template-columns: 6rem auto;
}

@media (min-width: 1200px) {
  #main {
    grid-template-columns: 12rem auto;
  }
}

@media (min-width: 1200px) {
  .xl\:pin-none {
    left: auto;
  }
}
</style>
<style>
.swal2-popup {
  @apply flex-col
}
</style>
