const SETTINGS = [
  {
    text: 'Users',
    url: '/settings',
    permission: { target: 'user', action: 'view'},
  },
];

const CONTENTS = [
  {
    text: 'Overview',
    url: '/contents',
    permission: { target: 'content', action: 'view'},
  },
  {
    text: 'Add',
    url: '/contents/add',
    permission: { target: 'content', action: 'update'},
  },
  {
    text: 'Publish',
    url: '/contents/publish',
    permission: { target: 'content', action: 'publish'},
  },
  {
    text: 'Conflicts',
    url: '/conflicts',
    permission: { target: 'content', action: 'update'},
  }
];

const IMAGES = [
  {
    text: 'Overview',
    url: '/images',
    permission: { target: 'image', action: 'view'},
  },
  {
    text: 'Upload',
    url: '/images/upload',
    permission: { target: 'image', action: 'upload'},
  }
];

const TAGS = [
  {
    text: 'Overview',
    url: '/tags',
    permission: { target: 'tag', action: 'view'},
  },
];

export default {
  SETTINGS,
  CONTENTS,
  IMAGES,
  TAGS
};
