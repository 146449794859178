import {useMutation, useQuery} from '@vue/apollo-composable';
import {computed} from 'vue'
import {
    ME, USERS, USER
} from '@/graphql/queries/user';
import {
    CREATE_USER, ASSIGN_ROLE, REMOVE_ROLE, UPDATE_USER
} from '@/graphql/mutations/user';

export function getMe(isEnabled) {
    const {
        result: meResult,
        loading: meLoading,
        error: meError,
        refetch: meRefetch,
        onResult: meOnResult,
        onError: meOnError,
    } = useQuery(ME, null, () => ({
        enabled: isEnabled.value
    }));

    const me = computed(() => meResult.value?.me ?? {});

    return {meLoading, meError, meRefetch, meOnResult, meOnError, me};
}

export function getUsers(args, enabled) {
    const {
        result: usersResult,
        loading: usersLoading,
        error: usersError,
        refetch: usersRefetch,
        onResult: usersOnResult,
        onError: usersOnError,
    } = useQuery(USERS, args, () => ({
        enabled: enabled
    }));

    const users = computed(() => usersResult.value?.users.data ?? []);
    const paginatorInfo = computed(() => usersResult.value?.users.paginatorInfo);

    return {usersLoading, usersError, usersRefetch, usersOnResult, usersOnError, users, paginatorInfo};
}

export function getUser(param, isEnabled) {
    const {
        result: userResult,
        loading: userLoading,
        error: userError,
        refetch: userRefetch,
        onResult: userOnResult,
        onError: userOnError,
    } = useQuery(USER, param, () => ({
        enabled: isEnabled.value
    }));

    const user = computed(() => userResult.value?.user ?? {});

    return {userLoading, userError, userRefetch, userOnResult, userOnError, user}
}

export function createUser() {
    const {
        mutate: createUserAction,
        loading: createUserLoading,
        error: createUserError,
        onDone: createUserOnDone,
        onError: createUserOnError,
    } = useMutation(CREATE_USER);
    return {createUserAction, createUserLoading, createUserError, createUserOnDone, createUserOnError};
}

export function updateUser() {
    const {
        mutate: updateUserAction,
        loading: updateUserLoading,
        error: updateUserError,
        onDone: updateUserOnDone,
        onError: updateUserOnError,
    } = useMutation(UPDATE_USER);
    return {updateUserAction, updateUserLoading, updateUserError, updateUserOnDone, updateUserOnError};
}

export function assignRole() {
    const {
        mutate: assignRoleAction,
        loading: assignRoleLoading,
        error: assignRoleError,
        onDone: assignRoleOnDone,
        onError: assignRoleOnError,
    } = useMutation(ASSIGN_ROLE);
    return {assignRoleAction, assignRoleLoading, assignRoleError, assignRoleOnDone, assignRoleOnError};
}

export function removeRole() {
    const {
        mutate: removeRoleAction,
        loading: removeRoleLoading,
        error: removeRoleError,
        onDone: removeRoleOnDone,
        onError: removeRoleOnError,
    } = useMutation(REMOVE_ROLE);
    return {removeRoleAction, removeRoleLoading, removeRoleError, removeRoleOnDone, removeRoleOnError};
}
