<template>
  <div class="xl:py-2 bg-gray-200">
    <div class="hidden xl:block uppercase font-bold text-grey-darker text-xs px-4 py-2">
      Menu
    </div>
    <div class="group relative sidebar-item with-children">
      <router-link v-for="(link, index) in links" :to="link.url" :key="index">
        <div class="text-base">{{ link.text }}</div>
      </router-link>
    </div>
  </div>

</template>

<script>
import {useRoute} from 'vue-router';
import {computed, unref} from 'vue';
import sidebarLinks from '../constants/sidebar-links';
import {useStore} from "vuex";

export default {
  name: 'Sidebar',
  setup() {
    const route = useRoute();
    const store = useStore();

    const links = computed(() => {
      let linkConst = route.path.split('/')[1].toUpperCase();
      if (linkConst === 'CONFLICTS') { linkConst = 'CONTENTS'; }
      const allLinks = linkConst in sidebarLinks ? sidebarLinks[linkConst] : [];
      return allLinks.filter((link) => {
        const hasPermission = computed(() => store.getters['auth/hasPermission'](link.permission.target, link.permission.action));
        return unref(hasPermission);
      })
    });

    return {links};
  },
};
</script>

<style scoped>
a {
  @apply block xl:flex xl:items-center text-center xl:text-left shadow-sm xl:shadow-none py-6 xl:py-2 xl:px-4 border-l-4 border-transparent hover:bg-gray-900  hover:text-white
}
</style>
;
