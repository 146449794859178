<template>
  <header class="">
    <div class="flex items-center h-full text-lg w-full">
      <router-link class="w-48" to="/">
        <img src="/images/logo.png" alt="Icy Veins Logo" title="Icy Veins Logo" class="px-3">
      </router-link>
      <div class="w-full h-full px-3 flex items-center">
        <div class="flex items-center links w-full">
          <router-link to="/settings" v-if="hasUserPermission">Settings</router-link>
<!--          <router-link to="/games" v-if="hasContentPermission">Games</router-link>-->
          <router-link to="/contents" v-if="hasContentPermission">Contents</router-link>
          <router-link to="/images" v-if="hasImagePermission">Images</router-link>
          <router-link to="/tags" v-if="hasTagPermission">Tags</router-link>
        </div>
        <router-link to="/profile" class="float-right text-white mr-5">{{me.username}}</router-link>
        <button @click="doLogout" class="button bg-red-500 text-white float-right items-center">Logout</button>
      </div>
    </div>
  </header>
</template>
<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { getMe } from '@/compose/users';
import { logout } from "@/compose/auth";

export default {
  setup () {
    const store = useStore();
    const router = useRouter();
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);
    const { me } = getMe(isAuthenticated);

    const hasUserPermission = computed(() => store.getters['auth/hasPermission']('user'));
    const hasContentPermission = computed(() => store.getters['auth/hasPermission']('content'));
    const hasImagePermission = computed(() => store.getters['auth/hasPermission']('image'));
    const hasTagPermission = computed(() => store.getters['auth/hasPermission']('tag'));

    const links = computed(() => {
      return [{ route: '/settings', title: 'Settings' }];
    });

    const { logoutAction, logoutOnDone } = logout();

    const doLogout = function() {
      logoutAction();
    }

    logoutOnDone(function (result) {
      localStorage.removeItem('token');
      localStorage.removeItem('roles');
      store.commit('auth/setToken', null);
      router.go();
    });

    return {
      me,
      doLogout,
      links,
      hasUserPermission,
      hasContentPermission,
      hasImagePermission,
      hasTagPermission,
    };
  }
};
</script>
<style scoped>
header {
  @apply fixed z-50 h-16 w-full bg-gray-900 shadow flex items-center justify-between
}

.links a {
  @apply flex items-center text-white h-full px-4
}
</style>
