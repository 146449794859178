import { gql } from 'graphql-tag';

export const ME = gql`
    query ME{
        me {
            id
            status
            email
            name
            username
            roles {
                id
                name
                permissions {
                    id
                    name
                }
            }
        }
    }
`;

export const USERS = gql`
    query USERS($search: String) {
        users(search: $search) {
            data {
                id
                status
                email
                name
                username
                roles {
                    id
                    name
                    permissions {
                        id
                        name
                    }
                }
            }
            paginatorInfo {
                count
                currentPage
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`;

export const USER = gql`
    query USER($id: ID, $username: String, $email: String) {
        user(id: $id, username: $username, email: $email) {
            id
            status
            email
            name
            username
            roles {
                id
                name
                permissions {
                    id
                    name
                }
            }
        }
    }
`;
