import { gql } from 'graphql-tag';

export const LOGIN = gql`
    mutation LOGIN($email: String, $username: String, $password: String!) {
        login(email: $email, username: $username, password: $password) {
            token
        }
    }
`;

export const LOGOUT = gql`
    mutation LOGOUT {
        logout { id }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation FORGOT_PASSWORD($email: String, $username: String, $url: String!) {
        forgot(email: $email, username: $username, url: $url) { success }
    }
`;