import { createApp, provide, h } from 'vue';
import { createHttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import App from './App.vue';
import router from './router';
import store from './store';

import Swal from './vendor/useSweetAlert';
import 'sweetalert2/dist/sweetalert2.min.css';
import Notifications from '@kyvg/vue3-notification';
import './assets/styles/index.css';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { DefaultApolloClient } from "@vue/apollo-composable";
import BounceLoader from "vue-spinner/src/PulseLoader.vue";
// import CKEditor from "@ckeditor/ckeditor5-vue";

import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';

const httpLink = new createHttpLink({
    uri: process.env.VUE_APP_ICYVEINS_BASE_API
});

const uploadLink = new createUploadLink({
    uri: process.env.VUE_APP_ICYVEINS_BASE_API
})

const cache = new InMemoryCache({
    typePolicies: {
        user: ['id', 'email', 'username'],
        usersWithRoles: ['id', 'email', 'username']
    }
});

const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token');
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : null,
        }
    });

    return forward(operation);
});

const apolloClient = new ApolloClient({
    link: authLink.concat(uploadLink),
    // link: authLink.concat(httpLink).concat(uploadLink),
    cache: cache
})

router.beforeEach(async (to, from, next) => {
    const isAuthenticated = store.getters['auth/isAuthenticated'];

    document.title = 'Icy Veins Admin - ' + to.name;

    if (!isAuthenticated && to.path !== '/login' && to.path !== '/forgot') {
        return next({path: '/login'});
    } else if (isAuthenticated && to.name === 'Login') {
        return next({name: 'Home'});
    } else {
        next();
    }
});

createApp({
    setup() {
        provide(DefaultApolloClient, apolloClient);
    },
    render() {
        return h(App);
    }
})
    .use(store)
    .use(Notifications)
    .use(BounceLoader)
    .use(router)
    .use(Swal)
    // .use(CKEditor)
    .use(flatPickr)
    .mount('#app');
