import {useMutation, useQuery, useResult} from '@vue/apollo-composable';
import {computed} from 'vue'
import {
    LOGIN, LOGOUT, FORGOT_PASSWORD
} from '@/graphql/mutations/auth';
import {} from '@/graphql/mutations/user';

export function login() {
    const {
        mutate: loginAction,
        loading: loginLoading,
        error: loginError,
        onDone: loginOnDone,
        onError: loginOnError,
    } = useMutation(LOGIN);
    return {loginAction, loginLoading, loginError, loginOnDone, loginOnError};
}

export function logout() {
    const {
        mutate: logoutAction,
        loading: logoutLoading,
        error: logoutError,
        onDone: logoutOnDone,
        onError: logoutOnError,
    } = useMutation(LOGOUT);
    return {logoutAction, logoutLoading, logoutError, logoutOnDone, logoutOnError};
}

export function forgotPassword() {
    const {
        mutate: forgotPasswordAction,
        loading: forgotPasswordLoading,
        error: forgotPasswordError,
        onDone: forgotPasswordOnDone,
        onError: forgotPasswordOnError,
    } = useMutation(FORGOT_PASSWORD);
    return {forgotPasswordAction, forgotPasswordLoading, forgotPasswordError, forgotPasswordOnDone, forgotPasswordOnError};
}